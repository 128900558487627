import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Book from "../components/Book"
import * as DesignSystem from "../components/Design-system"

const BooksPage = ( props ) => {

  const data = props.data.allMdx.group;

  return(
    <Layout>
      <SEO title="Books" />

      <DesignSystem.H1>Books</DesignSystem.H1>
      <p>This is my personal list of books I've read and found interesting.
I mostly read on productivity, psychology or design. I re-designed the book covers but included a link to Amazon, so you can find it quickly!</p>

        <DesignSystem.H2>
          By year
        </DesignSystem.H2>

        {data.map( year  => 
        (
          <>
            <DesignSystem.H3>{year.fieldValue}</DesignSystem.H3>
            
            {year.edges.map( ( {node} ) => 
            (<Book
              title={node.frontmatter.title}
              author={node.frontmatter.author}
              category={node.frontmatter.category}
              cover={node.frontmatter.cover.publicURL}
              > 
              {node.frontmatter.description}
            </Book>)
            )}

          </>
        )
        ).reverse()}

      
    </Layout>
  );
}

export const queryYearGrouped = graphql`
  query MyQuery {
    allMdx(
      sort: {fields: [frontmatter___year], order: DESC }
      filter: { fileAbsolutePath: {regex: "/pages/books/"}
      })
    {
      group(field: frontmatter___year) {
        fieldValue
        totalCount
        edges {
          node {
            frontmatter {
              title
              description
              author
              category
              cover{
                publicURL
              }
            }
          }
        }
      }
    }
  }
`

const queryAll = graphql`
  query BooksQuery {
    allMdx(filter: {fileAbsolutePath: {regex: "/pages/books/"}}) {
      edges {
        node {
          frontmatter{
            title
            description
            author
            category
            cover{
              publicURL
            }
          }
        }
      }
    }
  }
  `
const queryYear = graphql`
  query BooksQuery {
    allMdx(
      filter: {
        fileAbsolutePath: {regex: "/pages/books/"},
        frontmatter: { year: { eq: "2012" } }
      }
    ) {
      edges {
        node {
          frontmatter{
            title
            description
            author
            category
            cover{
              publicURL
            }
          }
        }
      }
    }
  }
`


export default BooksPage;
