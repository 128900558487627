import React from 'react'
import styled from 'styled-components'
import * as DesignSystem from "../components/Design-system"
import * as tokens from "../tokens/tokens";
import { ThemeContext } from "../tokens/theme"


const $Card = styled.div`
    padding: 0;
    cursor: pointer;
    
    &.selected{
      
       background: ${props => props.theme.colorBackground};
    }

    & h3{
        font-size: ${tokens.typography.fontSize.md};
        line-height: ${tokens.typography.lineHeight.sm};;
        font-family: ${tokens.typography.fontFamily.heading};
        padding: 0;
        padding-bottom: 0px;
        margin-bottom: 0px;
        /* display: ${(props) => props.selected ? "none" : "block"}; */
    }

    &.selected h3{
        display: none;
    }
`

const $Meta = styled.div`
    padding: 0 0px;
    font-size: ${tokens.typography.fontSize.xs};
    color: ${props => props.theme.colorTextPrimary};
    margin-bottom: 0px;
`

const $Cover = styled.img`
    display: block;
    float: left;
    width: ${(props) => props.selected ? "200px" : "80px"};;
    margin: 16px 0px 16px 16px;
    position: relative;
    border-radius: 4px;
    transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1) ;
`

const $Content = styled.div`
    font-size: ${tokens.typography.fontSize.md};
    color: ${props => props.theme.colorTextPrimary};
    padding: 16px 16px 16px 16px;
`
const $Description = styled.div`
    padding: 0 16px 16px 16px;
    display: ${(props) => props.selected ? "block" : "none"};
`

const $Stack = styled.div`
    display: flex;
    align-items: start;
`

class Book extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            selected: false,
            expanded: false
        }
    }

    onSelect = () => {
        this.setState(prevState => ({
            selected: !prevState.selected
        }));
    }

    render() {
        return(
            <ThemeContext.Consumer>
            { context => (

            <$Card theme={context.colorTheme} onClick={this.onSelect} className={this.state.selected ? 'selected' : ''}> 
                <$Stack>
                    <$Cover selected={this.state.selected} src={this.props.cover} />
                    <$Content theme={context.colorTheme}>
                        <h3 selected={this.state.selected}>{this.props.title}</h3>
                        <$Meta theme={context.colorTheme}>
                        <span>{this.props.author}</span> <br></br> <span># {this.props.category}</span>
                        </$Meta>
                    </$Content>
                </$Stack>
                <$Description selected={this.state.selected}>
                    
                    {this.props.children}
                </$Description>
            </$Card>

            )}
            </ThemeContext.Consumer>
        );
    }
}
export default Book;